import enUS from 'antd/lib/locale/en_US';
import { ConfigProvider } from 'antd';
import { Provider } from 'react-redux';
import React from 'react';
import ReactGA from 'react-ga4';

import { store } from './store';

import Routes from './routes';

import './App.less';
import NotificationsProvider from './context/notifications';

if (process.env.REACT_APP_MEASUREMENT_ID) {
  ReactGA.initialize(process.env.REACT_APP_MEASUREMENT_ID);
}

const App = (): JSX.Element => (
  <Provider store={store}>
    <ConfigProvider locale={enUS}>
      <NotificationsProvider>
        <Routes />
      </NotificationsProvider>
    </ConfigProvider>
  </Provider>
);

export default App;
